.scout-wrapper{
    display:flex;
    height: 100%;
    flex-direction: column;
}

.scout-accountlist{
    flex:.25;
    border-right:1px solid rgb(213 213 213);
    max-width:25%
}

.scout-chatlist {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.scout-list{
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.scout-list.overflow{
    overflow:auto
}

.scout-list-internal{
    flex: 1
}

.scout-chatwindow{
    flex:.75
}

.scout-list-item-account,
.scout-list-item-chat{
    height:5rem;
    max-height:5rem;
    min-height:5rem;
    border-bottom: 1px solid #f1f1f1;
}

.scout-list-item-account.open{
    border-left: 6px solid #7fffb0;
}

.scout-list-item-account.connecting{
    border-left: 6px solid #ffc87f;
}

.scout-list-item-account.close{
    border-left: 6px solid #ff927f;
}

.scout-list-item-account.active{
    background-color: #21446c;
    border-left: 6px solid #7fc1ff;
}

.scout-contact-item.deny{
    background-color: #ff000038;
}

.scout-contact-item.deny:hover{
    background-color: #ff000088;
}

.scout-list-item-chat.active{
    background-color: #3369a7;
}

.bluepadding,
.scout-list-item-chat{
    border-left: 6px solid #7fc1ff;
}

.scout-title-accountheader,
.scout-title-subheader,
.scout-title-header{
    display:flex;
    gap:.2rem;
    font-weight: bold;
    width:100%;
    align-items:center
}

.scout-title-accountheader .icon{
    display:flex
}

.scout-title-accountheader{
    justify-content: space-between;
}


.scout-title-accountheader.active,
.scout-title-header.active{
    color:white
}

.scout-title-header.active .icon{
    color:white
}

.scout-info-bearer{
    display:flex;
    gap: 1rem;
    position:relative
}

.scout-window-header {
    height: 4rem;
    background: rgba(33, 68, 108, 0.06);
    color: #21446c;
    border-bottom: 2px solid #f1f1f1;
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    font-weight: bold;
    gap:.5rem;
    justify-content: space-between
}

.scout-window-header .right,
.scout-window-header .left{
    display:flex;
    gap:.5rem;
    align-items: center;
}

.scout-window-header .left{
    cursor:pointer
}

.scout-window-header .icon,
.scout-title-header .icon{
    color:#21446c;
    font-size:0em
}

.scout-window-header .text{
    color:black
}


.scout-window-content .scout-window-content-list{
    padding: 1rem;
    height: 100%;
    overflow:auto;
}

.scout-window-content-item.right{
    justify-content: right;
}

.scout-conversation-box {
    padding: 0.6rem 0.5rem 1.4rem 0.5rem;
    position: relative;
    border-radius: .25rem;
    min-width: 7rem;
    min-height: 3rem;
    color: white;
    max-width: 50%;
    white-space: pre-line;
    word-break: break-word;
}

.scout-conversation-box video,
.scout-conversation-box img{
    max-height:20rem;
    max-width:30rem;
    height:100%;
    width:100%
}

.scout-conversation-sticker {
    max-width: 190px !important;
    max-height: 190px !important;
}

.right .scout-conversation-box{
    background: #5399DB
}

.left .scout-conversation-box{
    background: #21446c
}

.restricted .scout-conversation-box{
    background: #ff927f
}
.scout-window-main-frame{
    height:100%;
    display:flex;
    flex-direction: column;
    overflow:hidden
}

.scout-window-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.scout-conversation-time {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.15rem .33rem;
    font-size: .65em;
}

.scout-conversation-reaction {
    position: absolute;
    bottom: -0.8rem;
    right: -0.5rem;
    font-size: 0.85em;
}

.scout-alert-padding{
    padding:1rem
}

.scout-time-bearer {
    position: absolute;
    right: 0;
    bottom: -1rem;
    font-size:.85em
}

.scout-chatlist .finished-chats-filter form{
    width:100%
}

.scout-limit-text{
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.scout-wrapper-text{
    display:flex;
    gap:.2rem;
    display:flex;
    justify-content: space-between;
    width:100%
}

.scout-align-flex{
    display:flex;
    align-items: center
}

.scrolldown-box.scout{
    bottom: 1rem
}

.scout-preview-message{
    display: flex;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal-scout-table > div{
    min-height: 30rem;
    max-height: 30rem;
}

.modal-scout-search > div{
    min-height: 90%;
    max-height: 90%;
}

.scout-edited-message-span{
    position:relative;
    cursor: pointer
}

.scout-edited-message-span > span{
    position: absolute;
    left:0;
    font-size: 0.7em;
    bottom: -1.2rem;
    min-width: 3rem
}

.scout-searchbar {
    display: flex;
    align-items: right;
    justify-content: flex-end;
    border-bottom: 1px solid #d5d5d5;
}

.scout-accounts {
    display:flex;
    height: 95%;
    overflow:hidden
}