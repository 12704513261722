.voice-container {
	padding: 1rem;
	padding-top: 0.5rem;
	flex: 1
}

.action-button {
    min-width: 0;
    padding: 0 2.5px;
}
