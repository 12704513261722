.multistepdialog h4,
.multistepdialog h3{
    margin:1.5rem 0rem;
    font-weight:bold
}

.MTSBox {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 0rem 2rem;
    min-height: 10rem;
    cursor: pointer;
    box-shadow: 1px 1px 8px 1px #b7b7b759;
}
.MTSBox:hover{
    background-color: #e8e8e840
}

.MTSBox h4{
    color: #797979;
    margin:1.5rem;
    font-weight: bold;
    margin-bottom: 0;
}
.MTSBox h6{
    padding: 2rem 2rem;
    margin: 0;
    font-weight: bold;
    font-size: 0.65em;
    line-height: 0.8rem;
    color: #878787;
}




.MTSflexed {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap:wrap
}

.MTSBox.active{
    background-color:#e8e8e8
}

