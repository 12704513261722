.graph-reports-filter{
    display: flex;
    gap:1rem
}

.graph-report-list{
    display: flex;
    gap: .5rem;
    flex-wrap: wrap
}

.chats-channel-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.chats-channel-first-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-height: 20rem;
}

.chats-channel-second-row {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 3rem;
}