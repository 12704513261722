.additional-data {
	font-size: 17px;
    font-weight: bold;
    color: grey;
    margin: 0;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.additional-data.checked {
    color: #21446c;
}

.additional-data-row {
    display: flex;
    gap: .5rem;
    margin-top: 1rem;
}
