.groups {
	display: flex;
}

.item-group {
	margin-right: 5px;
    color: white;
    font-size: 10px;
    padding: 3px 3px 1px 3px;
    border-radius: 3px;
}

.active-user {
    color:  #5F8963;
}

.inactive-user {
    color: #C95F50;
}

.settings-user {
    color: #5c5c5c;
    cursor: pointer;
}

.buttons-users {
    padding-top: 15px;
    display: flex;
    float: right;
}

.settings-body-bot .MuiDataGrid-main .MuiDataGrid-cell div {
    display: flex;
    align-items: center;
}

.input-settings{
    padding: 1rem;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    min-height: 100%
}

.input-settings .flexbox{
    width:fit-content
}

.settings-phone-v2{
    height:100%
}