.frame {
	background-color: #f1f1f1;
	border-radius: 20px;
	padding: 1vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.3vw;
	font-weight: 500;
	color: #21446C;
}

.frame-body {
	text-align: center;
	display: flex;
	align-items: center;
	height: inherit;
	font-size: 200%;
}