.selections-template {
	height: 70px;
}


.status-fix-size{
	min-width:6rem
}

.noBottom{
	margin-bottom:0;
}

.noTop{
	margin-top:0
}