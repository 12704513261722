.merge-content {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-top: 1rem;
	width: 100%;
}

.merge-content ul {
	min-width: 15rem;
}

.merge-content ul li {
	margin-bottom: 0.25rem;
}

.merge-content .item-selected {
	background-color: #5399DB;
	color: white;
}
