.marketplace-items {
	margin-top: 1rem !important;
	max-height: 20rem;
	overflow: auto;
}

.marketplace-items .marketplace-item-selected {
	background-color: #5399DB;
	color: white;
}

.marketplace-items .marketplace-icon-selected {
	color: white;
}
