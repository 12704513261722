.user-header, .user-footer {
  width: 100%;
}
.user-header {
  border-bottom: 1px solid #e7e7e7;
  background-color: rgba(33, 68, 108, 0.06);
  text-align: center;
  justify-content: center;
}
.user-footer {
  text-align: right;
  justify-content: right;
}
.actions {
	position: absolute;
  right: 0;
}

.input-users-modal {
  padding-bottom: 20px;
}

.voice-settings {
  border: 1px solid #c4c4c4;
  border-radius: .25rem;
  padding: .25rem 1rem;
  margin-bottom: 1rem;
}

.voice-settings .voice-settings-options {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}