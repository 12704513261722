.graph-main-divisor{
    display: flex;
    height: 100%
}

.graph-main-table{
    flex:1;
    background-color: rgb(255, 255, 255);
}

.graph-main-dashboards{
    flex:1;
    background-color: rgb(248, 248, 248);
}

.graph-time-rows{
    display: flex;
    padding: 1rem;
    height: 100%;
    gap:.5rem
}

.graph-time-rows.column{
    flex-direction: column;
}

.graph-box{
    display:flex;
    flex-direction: column;
    width: 10rem;
    align-items: center;
}

.graph-box.row{
    flex-direction: row;
}

.graph-box > div{
    padding: .5rem;
    width: 100%;
    display:flex;
    justify-content: center;
}

.graph-label-bubble{
    padding: .15rem .25rem;
    border-radius: .25rem;
    color:white
}