.usage-progress-bar span.MuiLinearProgress-colorPrimary {
	background-color: #e7e7e7;
}

.usage-progress-bar span.MuiLinearProgress-colorPrimary span.MuiLinearProgress-barColorPrimary  {
	background-color: #5399db;
}

.usage-history-chart-container .recharts-default-tooltip {
	padding: 15px 25px !important;
	font-weight: 500;
}

.usage-history-chart-container .recharts-default-tooltip p {
	margin-bottom: 15px !important;
	text-align: center;
}

.whatsapp-consume{
	height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #d5d5d5;
    border-radius: .25rem;
}