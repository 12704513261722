.admin-login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    background-color: #e2e2e2;
}

.admin-login-background-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 100%;
    background-color: #5399db;
}

.admin-login-container {
    background-color: white;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 25%;
}

.admin-login-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #21446c;
    font-weight: bold;
}

.admin-login-input {
    width: 100%;
    margin-bottom: 1.5rem;
}

.admin-login-button {
    width: 100%;
    margin-top: 2rem;
}

.admin-login-alert {
    margin-top: 1rem;
    margin-bottom: -1rem;
    width: 100%;
    padding: .15rem 1rem;
}

#outlined-adornment-password + div button:hover {
    background-color: transparent;
}
