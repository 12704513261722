.contactHistory-box-1 {
	height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.contactHistory-iconButton {
    margin-left: 10px;
}
.contactHistory-box-2 {
	display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    flex-direction: column;
    overflow-y: auto;
}
.contactHistory-avatar {
	height: 75px;
    width: 75px;
}
.contactHistory-box-3, .contactHistory-box-4 {
	cursor: pointer;
}
.contactHistory-box-3 {
	margin-top: 10px; 
	margin-bottom: 5px;
}
.contactHistory-box-4 {
	margin-bottom: 5px; 
	font-size: 90%;
}
.contactHistory-box-8 {
	margin-bottom: 20px; 
	font-size: 90%;
	color: #68686B;
}

.contactHistory-box-9{
	display: flex;
    gap: 1rem;
    padding: .5rem;
    justify-content: center;
	flex-wrap:wrap
}

.contactHistory-box-5 {
	padding: .5rem;
    text-align: -webkit-center;
}
.contactHistory-divider {
	margin-top: 5px;
    margin-bottom: 10px;
}
.contactHistory-box-6 {
	font-size: 11px;
	margin: 2px 0;
}
.contactHistory-box-7 {
	overflow: auto;
	max-height: 10rem;
}
.noHistory {
	font-size: 80%;
	margin-top: 2vh;
}

.header-profile {
	display: flex;
	background-color: rgba(33, 68, 108, 0.06);
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.title-profile {
	height: 55px;
    width: 60%;
    text-align: center;
    align-self: center;
    display: flex;
    align-items: center;
    color: #68686B;
}

.avatar-profile {
	margin-top: 20px;
}
