.circle {
    position: absolute;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    margin-top: 30px;
    margin-left: 25px;
    border: 2px solid #5399db;
}
.login {
    background-color: #17E917;
}
.logoff {
    background-color: red;
}
.pause {
    background-color: yellow;
}

.name-queues {
    display: grid;
    margin-top: 10px;
}

small {
    font: inherit;
}
