.custom-pie-chart-2 {
	width: 21vw!important;
}

.custom-pie-chart-2 > svg {
	width: 22vw!important;
}

.center-graphics {
	display: flex; 
	flex-direction: column; 
	place-content: center;
	justify-content: center; 
	align-content: center; 
	align-items: center;
	margin-left: auto;
}