.form-confirm-password {
	display: flex; 
	height: 99vh; 
	justify-content: center; 
	align-content: center;
	align-items: center;
    width: 40vw;
}
.exit {
	margin-top: 15px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}
.title {
	color: #21446C;
    font-weight: 500;
    padding-left: 10px;
}