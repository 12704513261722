

.kwik-install-background{
    width:100vw;
    height:100vh;
    background-color:#000000ab;
    position:fixed;
    z-index:99999;
    display:none;
}

.mobile-show {
    display: flex;
    align-items: center;
    justify-content: center;
}

.kwik-install-modal {
    background-color: white;
    width: 90%;
    padding: 2rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    max-height: 85vh;
    overflow: auto;
}

.kwik-install-modal img {
    width: 100%;
}