.metrics-body {	
	padding: 0.5rem;
	width: 100%;
	height: 100%;
}

.metrics-grid {
	height: 97vh;
	width: 100%;
	display: grid;
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-areas:
		"enterprises queues agents"
		"ongoing all-chats chatbot"
		"exchanged notifications today-agents";
}

.agents {
	grid-area: agents;
}

.notifications {
	grid-area: notifications;
}

.reactivation {
	grid-area: reactivations;
}

.today-agents {
	grid-area: today-agents;
}

.enterprises {
	grid-area: enterprises;
}

.ongoing {
	grid-area: ongoing;
}

.exchanged {
	grid-area: exchanged;
}

.queues {
	grid-area: queues;
}

.total {
	grid-area: total;
}

.chatbot {
	grid-area: chatbot;
}
