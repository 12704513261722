.import-contact-dialog .MuiPaper-root{
    height:100%
}

.import-contact-list{
    display:flex;
    flex-direction:column;
    gap:.25rem
}

.import-contact-dialog .import-contact-content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.import-contact-display-item{
    display:flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right:0;
    height:2.5rem;
}


.import-contact-display-item.header{
    font-weight: bold;
    padding-right:.35rem
}


.import-contact-display-item > span {
    flex:1;
    height:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.25rem;
}
.import-contact-display-item > span.error{
    background-color:rgba(255, 0, 0, 0.25)
}

.import-contact-display-item.item{
    cursor:auto;
    padding:0;
    margin: 0.25rem 0rem;
    font-size:.8em
}
.import-contact-display-item.item >span {
    border:1px solid rgba(158, 158, 158, 0.25);
}

.import-contact-display-item > span > div {
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.import-contact-display-item > span > button {
    display: none
}

.import-contact-display-item > span:hover > button{
    display:flex
}

.import-contact-display-item:hover > span.item-last > button {
    display:flex
}

.import-contact-display-item > span.item-last {
    flex: 0.2 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-import-input::before{
    border: 0
}
.edit-import-input input{
    font-size:.8em;
    background-color:white
}

.import-contact-segment-icon {
    display: flex;
    gap: .25rem;
    align-items: center;
}