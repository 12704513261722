.options-menu-title{
    font-size: 1.5em;
    font-weight: bold;
    color: #21446c;
}

.options-menu-title .return-menu{
    cursor:pointer
}

.options-menu-title .return-menu:hover{
    filter:hue-rotate(45deg)
}

.options-menu-title > button{
    width: 2rem
}

.menu-icon {
    right: 0.5rem;
    position: absolute;
    font-size: 1.25rem;
}
