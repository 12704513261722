.bar-chart-report > .recharts-legend-wrapper {
	display: none!important;
}

.queues-legend {
	display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
}

.stacked-bar-chart {
    height: 100%;
}