
.channelbox{
    width: 10rem;
    padding: 1rem;
    display: flex;
    aspect-ratio: 1 / 1;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border: 1px solid rgb(233, 233, 233);
    flex-direction: column;
    gap:.5rem;
    cursor:pointer
}

.channelbox:hover{
    filter:brightness(.8)
}

.channelbox:active{
    filter:saturate(.9)
}

.channelbox svg{
    width:5rem
}

.wrapper{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}