*, :after, :before {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
   overflow-x: hidden !important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type="file"] {
  width: 100%;
}

#root {
  overflow: hidden;
  height: 100%;
}

.kwik-mainWrapper{
  height:100%;
  display:flex
}

.kwik-mainRoute{
  width:100%;
  height:100%;
  position:relative;
  overflow:hidden;
  flex:1
}

/* Adjust lower right notification position */
.rnc__notification-container--bottom-right {
  bottom: 5px;
  position: fixed;
}

.MuiDataGrid-toolbarContainer .BaseBadge-badge.MuiBadge-badge.MuiBadge-standard {
  font-size: 0.5rem;
  height: 1rem;
  min-width: 1rem; 
}

.kwik-cortex-open-btn {
  min-width: 56px;
}

.kwik-cortex-open-btn:hover {
  background-color: transparent;
}

.kwik-cortex-logo {
  width: 5rem;
  margin: -10rem;
}

.widgets-container {
  position: absolute;
  display: flex;
  right: 5.25rem;
  top: 14px;
  z-index: 1300;
  gap: 0.75rem;
}
