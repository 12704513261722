@media (max-width: 960px) {
    .left-container {
        display: none;
    }    
}


.kwik-cta-button{
    background-color:#ebebeb;
    color:#1b426c;
}

.kwik-cta-button:hover{
    color:#ebebeb
}