.sideuploader{
    z-index: 9999;
    background-color: rgb(199, 199, 199);
    position: fixed;
    bottom: 0;
    right: 0;
    border-top-left-radius: .25rem;
    padding: .25rem 1rem;
    cursor:pointer
}

.retracted{
    background-color: rgba(199, 199, 199, 0.527);
}

.sideuploader .flexcolumn{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.sideuploader .flexrow{
    display:flex;
    flex-direction: row;
    gap:.5rem
}