@media (min-width: 60rem) {
	.background-mobile {
    	display: none;
    }
}
.background-mobile {
	background-color: #5399db;
	color: #21446c;
	position: absolute;
	z-index: 10000;
	top: 0;
 	left: 0;
 	right: 0;
 	bottom: 0;
 	padding: 3%;
}
.popup-mobile {
	background-color: white;
	border: 1px solid #ececec;
	border-radius: 10px;
   padding: 3%;
   width: 40vw;
   left: 27%;
}
#title-popup {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 10px;
}
#body-popup {
	font-size: 17px;
	text-align: justify;
}
.popup-mobile, .logoIcon {
	position: relative;
	top: 15%;
}
.logoIcon {
	width: 15vw;
	left: 37vw;
	margin-bottom: 50px;
}