.clients-body {
    padding: 20px;
}

.clients-buttons {
    display: flex;
    width: 100%;
}

.button-add-group,
.button-notify,
.button-add,
.button-import,
.button-info,
.button-notification,
.button-table,
.button-generate,
.button-refresh {
    margin-bottom: 1rem;
}

.button-campaign,
.button-notification,
.button-table,
.button-refresh {
    float: right;
}

.clients-table-actions {
    text-align: right;
    margin-bottom: -35px;
    margin-right: 5px;
}

.button-add-group-selected,
.button-delete-selected {
    color: #242424;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 4px 5px;
    border-radius: 4px;
    z-index: 1;
}

.button-add-group-selected{
    margin-left: 6px;
}

.button-campaign,
.button-client-group{
    align-items: start;
}
.push-both{
    margin: 15px 0px;
}
.tableWrapper{
    padding:1rem
}

.button-client-group,
.button-notify,
.button-campaign,
.button-notification,
.button-add,
.button-import,
.button-table,
.button-generate,
.input-end-datetime {
    margin-left: 10px;
}

.button-info {
    margin-left: 5px;
}



.button-add {
    color: #fff;
}

.button-refresh {
    margin-right: 5px;
    color: #333;
}

.box-msg {
    margin-inline-end: auto;
    width: 100%;
}

.action-button {
    min-width: 0;
    padding: 0 2.5px;
}

.action-button.delete {
    color: #C95F50;
}


.question-icon {
    color: #333;
    font-size: 1.25rem;
}

.info-close {
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: 2px;
}

.close-icon {
    font-size: 1rem;
}

.inputPlaceholder label{
    font-size: 1em;
    margin-top: .25rem;
    margin-left: .25rem;
    font-weight: bold;
}

.custom-toolbar-buttons {
    justify-content: space-between;
}

.custom-toolbar-buttons button{
    color:black
}

.import-information {
    position: absolute;
    background-color: white;
    width: 300px;
    border-radius: 5px;
    border: 1px solid rgba(224, 224, 224, 1);
    z-index: 4;
    padding: 10px 15px;
    margin-left: 7.2rem;
    margin-top: 22rem;
    box-shadow: 5px 5px 5px;
}

.info-header {
    display: flex;
}

.info-title {
    margin-inline-end: auto;
    font-weight: bold;
    font-size: 15px;
}

.info-description {
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.info-topic {
    font-size: 13px;
}

.example-file {
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    display: flex;
    place-items: center;
    cursor: pointer;
}

.example-csv {
    font-size: 12px;
    background-color: #21446c;
    color: white;
    font-weight: bold;
    padding: 3px;
    border-radius: 5px;
    margin-right: 5px;
}

.example-text {
    text-decoration-line: underline;
}

.segment-item,
.client-group-item,
.notification-status {
    margin-right: 5px;
    color: white;
    font-size: 10px;
    padding: 3px 3px 1px 3px;
    border-radius: 3px;
}

.group-list-flexbox,
.group-flexbox{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: .5rem;
}

.flexend{
    align-items:flex-end
}

.group-list-flexbox .clients,
.group-flexbox .clients{
    display:flex;
    overflow: hidden;
}

.group-list-flexbox .click,
.group-flexbox .click{
    justify-content: center;
    border-radius: .2rem;
}

.segment-item,
.client-group-item {
    background-color: gray;
}
.segment-item{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 2rem;
    padding: .5rem;
    justify-content: space-between;
    width: max-content;
}
.segment-item .each-group-name {
    white-space: nowrap;
    max-width: 8rem;
    overflow: hidden;
    cursor: pointer;
    padding: .5rem 1rem;
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft{
    overflow-x:auto !important
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft::-webkit-scrollbar{
    height:.25rem;
    border-radius:0.25rem
}

.pointer{
    cursor:pointer
}

.each-group-div {
    display: flex;
    max-width: 1rem;
    align-items: center;
    justify-content: center;
}

.segment-item svg{
    transform:rotate(90deg);
    color: white;
}

.each-group-div button {
    min-width: 1rem;
    padding: 0;
}

.group-list-flexbox {
    flex-wrap: wrap;
    justify-content: left;
    margin: 1rem 0rem;
    padding: 0 0.35rem;
    max-height: 10rem;
    overflow: auto;
    height:min-content !important
}

.groupDropDown {
    position: fixed;
    top: 0;
    left: 0;
    width: max-content;
    gap: 0.25rem;
    display: none;
    background-color: white;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    z-index: 3;
    box-shadow: 1px 1px 10px 1px #a5a5a5;
    border-radius: 0.25rem;
    cursor: auto;
    padding: .5rem;
}

.groupDropDown button {
    padding: 0.25rem 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.groupDropDown:hover,
.helperButton:focus + .groupDropDown{
    display:flex;
}
.helperButton{
    position: absolute;
    top: -1000%;
    left: -1000%;
}

.clientGroupBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.clientGroupDialog {
    position: absolute;
    top: 75px;
    left: 0;
    z-index: 2;
    background-color: white;
    max-width: 32rem;
    min-width: 10rem;
    min-height: 2rem;
    border-radius: .2rem;
    border: 1px solid #a5a5a5;
    padding: 1rem;
    box-shadow: 1px 1px 13px 1px gainsboro;
}






.notification-status.success {
    background-color: #198754;
}

.notification-status.warning {
    background-color: #ffc107;
}

.notification-status.failure {
    background-color: #dc3545;
}

.icon-answered {
    color: #198754;
}

.icon-not-answered {
    color: #dc3545;
}

.view-template {
    margin-right: 10px;
}

.template-dialog-container {
    padding: 0px 25px 25px 25px;
}

.newCampaignTemplate{

    max-height: 15rem;
    overflow: auto;
    padding:0;
}






@media (max-width: 60rem) {
    .clients-body {
        padding: 10px;
    }

    .button-add-group,
    .button-notify,
    .button-add,
    .button-import,
    .button-info,
    .button-notification,
    .button-table,
    .button-generate,
    .button-refresh {
        margin-bottom: 16px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }

    /* .button-refresh {
        position: absolute;
    } */

    .button-add-group:not(:disabled){
        background-color: #00b3ff;
    }

    .button-notify:not(:disabled) {
        background-color: #ed6c02;
    }

    .button-add,
    .button-table,
    .button-add:hover,
    .button-table:hover {
        background-color: #21446c;
    }

    .button-add svg {
        fill: #fff;
    }

    .button-import {
        background-color: #5f8963;
    }

    .button-add-group:not(:disabled) svg,
    .button-notify:not(:disabled) svg,
    .button-add svg,
    .button-import svg,
    .button-table svg {
        fill: #fff;
    }

    .button-notification svg,
    .button-generate svg {
        fill: #21446c;
    }

    .button-notification {
        margin-left: 10px;
    }

    .start-textfield,
    .end-textfield {
        display: none;
    }

    /* These buttons are hidden for now since there's no use for then on mobile versions, but are already implemented for future use */
    .button-import,
    .button-info,
    .button-generate {
        display: none;
    }
}


