.multiple-selections {
	padding-top: 15px;
	padding-bottom: 15px;
}
.input-select {
	background-color: white!important;
    padding-right: 5px!important;
}
.hide-from-list{
	display:none
}