.enterprises-container {
	width: 100%;
	height: 100%;
	padding: 1rem;
}

.action-button {
	min-width: 0;
	padding: 0 2.5px;
}

.action-button.delete {
	color: #C95F50;
}

.enterprise-view-modal {
	padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.enterprise-adress-modal {
	display:flex;
	gap: 1rem;
	flex-wrap: wrap;
}

.enterprise-view-flex {
    display: flex;
    width: 100%;
    gap: 1rem;
}

.multiple-selections {
	padding: 0;
}
