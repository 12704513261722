.avatarName {
	border-radius: 100%;
	text-align: center;
	color: white;
}
.avatarName-big {
    font-size: 35px;
    padding: 15px;
    width: 80px;
    height: 80px;
}
.avatarName-medium {
	font-size: 14px;
	padding: 8px;
	height: 35px;
    width: 35px;
}
.avatarName-small {
	font-size: 11px;
	padding: 5px;
	height: 25px;
    width: 25px;
    margin-right: 5px;
}