.fake-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.fake-disabled:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.file-drop-container {
  border: 1px solid rgb(192, 192, 192);
  border-radius: 4px;
  color: rgb(33, 33, 33);
  padding: 16.5px 14px;
}

.file-drop-container:hover {
  border-color: rgb(33, 33, 33);
}

.file-drop {
  position: relative;
  height: 200px;
  width: 100%;
}

.file-drop span:nth-child(2) {
  margin: 8px 0;
}

.file-drop > .file-drop-target {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.email-input .file-drop > .file-drop-target{
  overflow: auto;
  gap: 0.2rem;
}

/* Applied when dragging file over the page */
.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  border: 3px dashed rgba(83, 153, 219, 0.5);
  background-color: rgba(83, 153, 219, 0.2);
  box-shadow: none;
  z-index: 50;
  opacity: 1;
}

/* Applied when dragging file over the target */
.file-drop > .file-drop-target.file-drop-dragging-over-target {
  color: rgb(83, 153, 219);
  box-shadow: 0 0 13px 3px rgba(83, 153, 219, 0.9);
  }


.button-box-new-template{
  border: 1px solid #c4c4c4;
  padding: .5rem;
  border-radius: .25rem;
  display:flex;
  flex-direction:column;
  gap:.5rem;
}