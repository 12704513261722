.dash-body {
	width: 100%; 
	height: 100%;
}
@media (min-width: 90rem) {
	#small-frame {
		height: 19vh!important;
	}
	#big-frame, #big-frame-2 {
		height: 40vh!important;
	}
	#tooltip-null-2 {
	    left: 30%!important;
	}
}
#small-frame {
	background-color: #f1f1f1; 
	height: 18vh;
	border-radius:  .5rem; 
	display: flex; 
	flex-direction: row; 
	justify-content: space-between;
	align-content: center;
	align-items: center;
	padding: 1vw;
}
#big-frame {
	background-color: #f1f1f1;
	height: 40vh;
	border-radius: .5rem; 
	display: flex;
	flex-direction: row; 
	justify-content: center; 
	align-content: center;
	align-items: center;
	padding: 1vw;
}
#big-frame-2 {
	background-color: #f1f1f1;
	height: 40vh;
	border-radius: .5rem; 
	padding: 1vw;
	display: flex;
    flex-direction: column;
	min-height:20rem
}

.dash-values {
	font-size: 2vw;
    font-weight: 500;
    display: flex;
}
#dash-grid {
	display: flex;
}
.dash-values-2 {
	font-size: 15px;
    font-weight: 500;
    display: flex;
}
#color-blue {
	color: #21446C;
}
#color-light-blue {
	color: #5399db;
}
#color-red {
	color: #C95F50;
}
#color-green {
	color: #5F8963;
}
#color-yellow {
	color: #d5ba7d;
}
.dash-centralizer{
	flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
}
.tooltip-centralizer{
	justify-content: flex-end;
	display:flex;
	width:100%
}
#dash-box-1 {
	display: flex; 
	flex-direction: column; 
	justify-content: center; 
	align-content: center; 
	align-items: center;
}
#dash-box-2 {
    display: flex;
    right: 8rem;
    z-index: 1500;
    align-items: center;
}
.dash-filter{
	
}
.dash-list {
	max-height: 100vh;
    overflow: auto;
}
.dash-queues {
	padding-top: 5rem;
	padding-bottom: 1rem;
}
.group-item {
	padding-left: 5rem;
	padding-right: 5rem;
	justify-content: center;
	height: 3rem;
}
.group-item > label {
    width: 10rem;
}
.barGraph {
	padding-bottom: 30px;
	height: 100%;
	text-align: -webkit-center;
}
#percentage {
	font-size: 1vw; 
	place-self: center;
	padding-left: 5px;
}

.toolbar-signal-icon {
	top: 19px;
    right: 225px;
    position: fixed;
    z-index: 1500;
}

.toolbar-refresh-icon {
	color: white;
	top: 12px;
    right: 125px;
    position: fixed;
    z-index: 1500;
}

.toolbar-filter-icon {
    top: 14px;
    right: 170px;
    position: fixed;
    z-index: 1500;
    height: 40px;
}

.toolbar-filter-icon > div > svg {
	color: white !important;
}

#operatorsGraph {
	display: flex;
}
#operatorsGraph > div {
	width: 100%!important;
	height: 40vh!important;
}
#operatorsGraph-footer {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: inline;
    font-weight: bold;
    font-size: 1.5vw;
}
.oG-footer-left {
	display: flex;
	float: left;
}
.oG-footer-right {
	display: flex;
	float: right;
}
#callsAndOperatorsGraph {
	width: 100%;
	height: 50vh;
    margin-top: -10vh;
}
#npsGraph {
	width: 120%;
	height: 55vh;
    margin-top: -7vh;
}
#title-length {
	width: 10vw;
}
.MuiDrawer-modal {
	z-index: 1200!important;
}
#grid-item {
	padding-bottom: 0;
}
#tooltip-null {
	position: relative;
    top: 50%;
}
@media (max-width: 70rem) {
	#tooltip-null-2 {
	    left: 10%!important;
	}
}
@media (max-width: 74rem) {
	#csatGraph > .recharts-wrapper > .recharts-surface {
		height: 37vh!important;
	}
}
#tooltip-null-2 {
    left: 20%;
}
#csatGraph {
	height: 15vh;
}
#csatGraph > .recharts-wrapper > .recharts-surface {
	width: 36vw;
	height: 42vh;
	margin-left: -7vw;
}
.fontSize-title > h6, .fontSize-title > p {
	font-size: 1vw;
}
.fontSize-value {
	font-size: 2vw;
}

.bar-chart-dash > .recharts-legend-wrapper {
	display: none!important;
}

.queues-legend {
	display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
}

.dashboard-custom-tooltip{
	background-color:white;
	border: 1px solid rgb(211, 211, 211);
	padding:.5rem;
	max-height:50rem;
	overflow:auto
}

.dashboard-custom-tooltip-label{
	font-size:1.2em;
	font-weight: bold;
}

.dashboard-custom-tooltip-list >li{
	padding:0;
	display:flex;
	gap:1rem;
	justify-content: space-between;
}

.dashboard-tab-selector{
	display:flex;
	padding: 1rem;
	gap: .5rem;
	flex-direction: column;
	height:100%
}

.dashboard-action-menu{
	display:flex;
	gap: 1rem;
	align-items: center;
}