.trial-form {
	display: flex;
	width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0 10rem;
}

.trial-form .trial-title {
	color: #21446C;
    font-weight: 500;
}
