.sankey-chart-container {
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin: auto;
}

.new-graphs-wrapper{
    width:100%
}

.new-graphs-wrapper .MuiTypography-subtitle1{
    max-width: 25rem
}
