.settings-category-title {
	font-size: 19px;
    font-weight: bold;
    color: #21446c;
    margin-bottom: 10px;
}

.settings-category-title:not(:first-child) {
    border-top: 1px solid #e2e2e2;
    margin-top: 15px;
    padding-top: 15px;
}

.mt-05 {
	margin-top: 5px;
}

.push {
	margin-top: 15px;
	margin-bottom: 15px;
}

.user-header {
	text-align: left !important;
}

.space-between {
	justify-content: space-between;
}

.align-right {
	float: right;
}
