.templates-header, .templates-footer {
  width: 100%;
}
.templates-header {
  border-bottom: 1px solid #e7e7e7;
  background-color: rgba(33, 68, 108, 0.06);
  text-align: center;
  justify-content: center;
}
.templates-footer {
  text-align: right;
  justify-content: right;
  padding-top: 20px;
}
.actions {
    position: absolute;
  right: 0;
}
.button-created {
	margin-left: 5px;
}
.character-counter {
	float: left;
    left: 0;
    position: absolute;
    margin-left: 25px;
    margin-top: -15px;
    background-color: #f2f4f6;
    color: #21446C;
    font-weight: bold;
    padding: 3px 6px 3px 6px;
    border-radius: 5px;
    font-size: 11px;
}

.positionUnset{
  position:unset;
  margin-left:0
}

.flexedGap{
  display: flex;
    gap: .5rem;
}

.templatePadding{
  padding:1rem 0rem 0rem 1.5rem;
  width: 100%;
  display:flex;
  flex-direction:column;
  gap:1rem
}

.templateFlexBox{
  justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    position: relative;
    display:flex;
    overflow: auto;
    max-height: 42rem;
    min-height: 4rem;
}

@media screen and (max-width:768px){
  .templateFlexBox{
    max-height: unset
  }
}

@media screen and (max-width:400px){
  .MuiPaper-root{
    margin:unset
  }

  .MuiPaper-root .MuiDialogContent-root{
    padding: 10px 12px
  }
}

.mediaVisualize{
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1rem 0rem;
}

.previewBoxStyle{
  width: 100%;
  padding: .75rem;
  border-radius: .25rem;
  background-color: gray;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
}
.wpp-card-main{
  width:100%;
  background-color:white;
  padding: 1rem;
  border-radius: .25rem;
}

.wpp-card-button{
  width: 100%;
  background-color: white;
  border-radius: .25rem;
  padding: 0.5rem 1rem;
  color: #0b67aa;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .5rem;
  justify-content: center;
}

.wpp-card-button:active{
  filter:contrast(0.8)
}


.PreviewTemplateDialog .MuiPaper-root{
  max-width:800px;
  width: fit-content;
}

.wpp-card-param{
  flex: 0.5;
  min-width: 20rem; 
}

.wpp-card-box{
  display: flex;
  align-items: flex-start;
  min-width: 20rem;
  flex: 0.5;
  margin-top: 2.4rem
}

.wpp-media-display{
  max-height: 10rem;
  max-width: 20rem;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5rem;
}

.wpp-media-display embed,
.wpp-media-display img {
  width: 100%;
  height: 100%;
  max-height: inherit;
  max-width: inherit;
}

.wpp-doc-display {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    min-height: 3rem;
    background-color: #efefef;
    cursor: pointer;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden
}

.wpp-doc-display:active {
  filter:contrast(0.8)
}

.templateFlexBox .input-parameter {
  margin-top: 0.5rem;
}

.templateFlexBox h6 {
  margin: 0;
  height: 1.5rem;
  margin-top: 1rem;
}

.templateFlexBox .relative-box {
  position:relative;
  margin-bottom: 1rem
}

.templateFlexBox .absolute-button {
  position: absolute;
  top:0;
  right:0;
}

.emojitextarea-wrapper-box .absolute-button{
  position: absolute;
  top:0;
  right:0;
}

.emojitextarea-wrapper-box .select-pop-up,
.templateFlexBox .select-pop-up{
    display:none;
    position: fixed;
    left: 0px;
    top: 0px;
    background: transparent;
    z-index: 1;
    padding-top:1rem;
    padding-left:1rem;
    padding-right:1rem;
}

.select-pop-up-backdrop{
  display:none;
  position:fixed;
  top: 0px;
  left: 0px;
  width:100%;
  height: 100%;
}

.select-pop-up-backdrop.active{
  display:block
}

.emojitextarea-wrapper-box .select-pop-up.active,
.templateFlexBox .select-pop-up.active{
  display:block
}

/* .emojitextarea-wrapper-box .absolute-button:active + .select-pop-up,
.templateFlexBox .absolute-button:active + .select-pop-up{
  display:block
} */

.emojitextarea-wrapper-box .select-list,
.templateFlexBox .select-list {
  padding:0;
  background: white;
  box-shadow: 1px 1px 8px 1px #a1a1a1;
  border-radius: .25rem;
  z-index:2
}

.select-list.insiderlist{
  position:absolute;
  right:0
}

.emojitextarea-wrapper-box .select-list-item,
.templateFlexBox .select-list-item {
  padding: 1rem 1.5rem;
  z-index: 2;
  font-size: 12px;
  min-width: max-content;
}


@media screen and (max-width:500px) {
  .wpp-card-box,
  .wpp-card-param{
    min-width:15rem
  }
  .wpp-card-param .file-drop-target label{
    font-size: .7rem;
  } 
  
}
