.report-graphs {
	width: 100%;
	height: 100%;
    overflow: auto;
}

@media (min-width: 90rem) {
	.chart-box {
		height: 100%;
	}
}

.chart-box {
	width: 100%;
	height: 100%;
	background-color: #f1f1f1;
	border-radius: 10px;
	padding-left: 1vw;
	padding-right: 1vw;
	padding-top: 1vw;
	padding-bottom: 2vw;
}

.csat-grade {
	text-align: center;
	font-weight: 500;
	font-size: 20pt;
}

.nps-grade {
	text-align: center;
	font-weight: 500;
	font-size: 20pt;
}

.fontSize-title > h6, .fontSize-title > p {
	font-size: 1vw;
}

.fontSize-value {
	font-size: 2vw;
}

.report-csat-value {
	width: 50%;
}

.report-csat {
	display: flex;
	align-items: center;
	height: 100%;
}

.report-nps {
	display: flex;
	place-content: center;
    height: 100%;
}

.report-nps-value {
	display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2rem;
}

.data-null {
	position: relative;
    top: 50%;
}