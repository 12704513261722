.settings-body ul.whatsapp-numbers li {
	display: flex;
	align-items: center;
	margin-bottom: 0.75rem;
}

.settings-body ul.whatsapp-numbers svg {
	margin-left: 1rem;
	color: green;
}

.settings-body ul.whatsapp-numbers svg,
.settings-body ul.whatsapp-numbers span {
	margin-right: 1rem;
}

.settings-body ul.whatsapp-numbers .number-status span {
	margin-right: 0;
	padding: 0 1rem;
}

.onboarding-connect-button {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	max-width: 8.5rem;
}

.onboarding-connect-button svg {
	margin-left: -0.5rem;
	margin-right: 0.25rem;
}

.whatsapp-numbers {
	padding-left: 1.5rem;
}

.whatsapp-numbers li:before {
	content: "\2022";
	margin-right: 0.5em;
}

.whatsapp-mumbers-info {
	display: flex;
	margin-top: 0.5rem;
}

.whatsapp-mumbers-info .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
	align-items: center;
}

.whatsapp-mumbers-info button {
	margin-right: 1rem;
}

.whatsapp-mumbers-info .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
	padding: 0 10px;
}
