.separateLine {
    border-bottom: 3px solid #a3a3a3;
    width: 50%;
    height: 1px;
    border-radius: 1px;
}

.flexedRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.multiButton {
    border-radius: 50%;
    width: 5rem;
    min-width: 2.5rem;
    aspect-ratio: 1 / 1;
    font-size: 1.5rem;
    cursor: pointer;
    border: 2px solid #a3a3a3;
    font-family:Arial
}

.activated{
    background-color:#21446C;
    color: white;
}
.activatedLine{
    border-bottom: 3px solid #21446C;
}

.disable{
    color:black;
    cursor:no-drop
}
.fill {
    width: 100%;
    display: flex;
    justify-content: center;
}
