.MTSBox {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 0rem 2rem;
    min-height: auto;
    cursor: pointer;
    box-shadow: 1px 1px 8px 1px #b7b7b759;
}
.MTSBox:hover{
    background-color: #e8e8e840
}

.MTSBox h4{
    color: #797979;
    margin-bottom: 0;
}
.MTSBox h6{
    padding: 2rem 2rem;
    margin: 0;
    color: #878787;
}

.MTSflexed {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.MTSBox.active{
    background-color:#e8e8e8
}