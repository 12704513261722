.email-input {
	height: 21.5rem; 
	display: flex;
    padding: .5rem;
    align-items: center;
    width: 100%;
    background-color: rgba(33, 68, 108, 0.06);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.email-input .email-input-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.email-input .email-input-actions,
.email-input .email-input-buttons {
    height: 3rem;
}

.email-input .email-input-buttons {
    text-align: right;
}

.email-input .email-input-content {
    height: 100%;
    text-align: right;
}

.email-input .email-input-content div:first-child {
    background-color: white;
}
