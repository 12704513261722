.internal-chat-container {
	display: flex;
    flex-direction: column;
    height: 35rem;
    width: 25rem;
    justify-content: space-between;
    background-color: #e7e7e7;
}

.internal-chat-container .internal-chat-chat {
	display: flex;
	flex: 1;
	max-height: 31.5rem;
}

.internal-chat-container .internal-chat-chat ul {
	display: block;
	width: 100%;
	background-color: white;
	margin: 0.5rem;
	margin-bottom: 0;
	border-radius: 5px;
    padding: 1rem;
    overflow: auto;
}

.internal-chat-container .internal-chat-chat .message-right {
	display: flex;
	justify-content: right;
	margin-bottom: 0.5rem;
}

.internal-chat-container .internal-chat-chat .message-left {
	display: flex;
	justify-content: left;
	margin-bottom: 0.5rem;
}

.internal-chat-container .internal-chat-chat .message-right .message-item,
.internal-chat-container .internal-chat-chat .message-left .message-item {
	display: flex;
    flex-direction: column;
    box-shadow: 0 1px 1px #CCC;
    padding: 0.4rem;
    max-width: 85%;
    position: relative;
    color: white;
    gap: 0.25rem;
}

.internal-chat-container .internal-chat-chat .message-right .message-item div:first-child,
.internal-chat-container .internal-chat-chat .message-left .message-item div:first-child {
	font-weight: bold;
}

.internal-chat-container .internal-chat-chat .message-right .message-item div:last-child,
.internal-chat-container .internal-chat-chat .message-left .message-item div:last-child {
	text-align: right;
	font-size: 90%;
}

.internal-chat-container .internal-chat-chat .message-right .message-item {
    border-radius: 5px 0px 5px 5px;
    background-color: #5399db;
}

.internal-chat-container .internal-chat-chat .message-left .message-item {
    border-radius: 0px 5px 5px 5px;
    background-color: #21446c;
}

.internal-chat-container .internal-chat-footer {
	display: flex;
	flex-direction: row;
	height: 2.5rem;
    background-color: #e7e7e7;
	margin: 0.5rem;
	gap: 0.5rem;
}

.internal-chat-container .internal-chat-footer .internal-chat-input input {
	background-color: white;
	border-radius: 5px;
}

.internal-chat-container .internal-chat-footer .internal-chat-input fieldset {
	border: 0;
}
