.content-dialog-rows{
    display:flex;
    flex-direction: column;
    gap:1rem
}

.automation-select-repeat{
    display:flex;
    gap:1rem;
    align-items: baseline;
    justify-content: space-between;
}

.automation-dayweek-selector{
    display:flex;
    gap: 1rem;
    justify-content: center;
}

.automation-end-form-control{
    display:flex;
    gap:1rem;
    align-items: center;
}

.automation-end-form-control > div {
    padding: 0
}

.automation-end-form-control > label{
    min-width: 5rem;
}

.automation-end-form-control > div.automation-align-text{
    padding-bottom: 0.1rem
}