.attachment-menu-popper {
	z-index: 10000;
}

.attachment-menu-popper div label:not(:first-child) {
	margin-top: 0.25rem;
}

.add-image {
	background-color: #a24ccb!important;
}

.add-video {
	background-color: #bf4e4e!important;
}

.add-document {
	background-color: #4167b0!important;
}

.attachment-btn span {
	width: 50px;
	height: 50px;
}

.attachment-btn.inactive span {
	opacity: 50%;
}
