.settings-body-bot{
    height:100%
}

.BuilderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 1rem;
    gap: 1rem;
}

.BuilderSelectorContainer {
    height: 100%;
    display: flex;
    gap: 1rem;
    flex: 1 1;
    flex-direction: column;
}

.BuilderSelectorBox {
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    border-radius: 0.25rem;
    align-items: flex-start;
}

.BuilderContent {
    flex: 5;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BuilderSelectorBox button{
    width:100%;
    justify-content: left;
    text-align:start
}

.piloto-modal-flex{
    display:flex;
    flex-direction: column;
    gap:1rem;
}

.BuilderBotSelectorBox{
    display: flex;
    border: 1px solid #dedede;
    border-radius: 0.25rem;
    align-items: center;
    justify-content: space-between;
}

.BuilderBotSelectorBox button{
    flex:1
}

.questionsbox{
    padding: .5rem;
    border: 1px solid #cdcdcd;
    border-radius: 0.25rem;
}

.questionsbox button{
    padding:0
}

.questionsboxquestion{
    border-radius: .25rem;
    justify-content: space-between;
    width:100%
}

.questionsboxheader{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding-right:.2rem;
    align-items: center;
 }

 .questionsboxquestion .MuiOutlinedInput-root{
    padding:0 0.3rem
 }

 .questionsboxok{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem .3rem;
    border: 1px solid #cdcdcd;
    border-radius: 0.25rem;
 }

 .draggable-list-containers{
    display:flex;
    gap:.5rem;
    flex-direction: column;
 }

 .draggable-item-container{
    padding:0;
    flex:1
 }

 .internal-question-box{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow-x:clip
 }

 .question-exceptions-label{
        display:flex;
        align-items: center;
        padding: 0rem .25rem;
        position: absolute;
        top: -10px;
        background-color: white;
        font-size: .8em;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
 }

 .question-exceptions{
    display:flex;
    align-items: center;
    padding: 0rem .25rem;
    background-color: white;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

 .rule-check-list {
    margin-top:0.5rem
 }