.templates-body {
	height: 100%;
}

.add-template {
	padding-top: 1rem;
	padding-left: 2rem;
}

.table-templates {
	padding: 1rem;
	height: 100%;
}

.column-status {
	text-transform: uppercase;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 16px;
    border: 1px solid transparent;
    width: auto;
}

.column-status-approved {
	background-color: #5F8963;
}

.column-status-submitted {
	background-color: #d5ba7d;
}

.column-status-rejected {
	background-color: #C95F50;
}

.column-components {
	overflow: hidden;
    text-overflow: ellipsis;
}

.column-actions {
	display: flex;
}

.icon-action {
	cursor: pointer;
	margin-right: 0.5rem;
}

.read-icon {
	color: #21446C;
}

.copy-icon {
	color: #21446C;
	width: 20px;
}

.delete-icon {
	color: #C95F50;
}

.icon-refresh {
	color: white !important;
    cursor: pointer;
    z-index: 1300;
}
