.metrics-login {
	height: 100vh;
	width: 500px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;
}

.metrics-login button {
	margin-top: 8px;
}
