@media (max-width: 760px) {
	.internal-chat-menu-container {
		display: none;
	}	
}

.internal-chat-menu-container > Button {
	background-color: #5166a6;
}

.internal-chat-menu-container .notification-circle {
	position: absolute;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    margin-top: -9px;
    margin-left: 25px;
    border: 2px solid #5399db;
    background: #17e917;
    z-index: 2000;
}

.internal-chat-menu-container > Button:hover {
	background-color: #1b426c;
}

.internal-chat-menu-container .toolbar-chat-icon {
	color: white;
	font-size: 1.25rem;
}

.internal-chat-portal-container > div.MuiPaper-root {
	margin-top: 4vh;
	box-shadow: 5px 5px 5px grey !important;
	border-radius: 7px;
}

.internal-chat-portal-container > div.MuiPaper-root > ul {
	padding: 0 !important;
}
