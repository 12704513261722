.callback-container {
	padding: 3px 10px 3px 10px;
    margin-top: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}

.callback-container.first {
    margin-top: 30px;
}

.chat-events-default-btn {
    float: right;
    margin-top: -37px;
}

.table-vars > tr > td:first-child {
    padding: 5px 25px;
}

.push-bottom{
    margin-bottom:1rem !important
}

.category-title {
	font-size: 19px;
    font-weight: bold;
    color: #21446c;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.new-crm-selected-info {
    margin-top: 1.2rem; 
    padding-top: 0.5rem;
}

.new-crm-selected-info div {
    padding: 0;
}

.marketplace-subheader {
    font-size: 1rem;
    margin-left: .25rem;
    margin-top: 0.25rem;
}

.marketplace-subheader.divider {
    border-top: 1px solid #bdbdbd;
}
