.bgMessage {
	display: flex;
    flex-direction:  column;
    box-shadow: 0 1px 1px #CCC;
    padding: 3px;
    max-width: 60%;
    position:relative
}

.bgMessage.different-chat{
    filter: opacity(0.8);
}

.bgMessage-chatbot {
    min-width: 10rem;
}
#left-user {
    border-radius: 0px 5px 5px;
}
#right-user {
    border-radius: 5px 0px 5px 5px;
}
#bgBot {
	margin-left: 3px;
    text-align: left;
    height: 15px;
    display: flex;
    align-items: flex-end;
    margin-top: 7px;
}
#iconBot {
	fill:  white;
	width: 15px;
    margin-right: 3px;
}
.bgTime {
    margin-right: 5px;
    text-align: right;
    height: 15px;
    margin-bottom: 5px;
}
.messageItem-box-1 {
    margin-bottom: 10px;
    display: flex;
}

.messageItem-box-1.reply-from {
    margin: .25rem;
    margin-bottom: .5rem;
    font-size: 90%;
}

.messageItem-box-1.reply-from .bgMessage {
    max-width: 85%;
    background-color: #ccc;
}

.messageItem-box-2 {
    margin: 1px 40px 0px 3px;
    margin-bottom: 4px;
    line-height:1.25rem;
    white-space:pre-wrap;
    word-break: break-word;
}

.message-email-attachments{
    padding-left: 1.5rem;
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
}

.attachment-email-item{
    font-size: .8em;
    font-weight: bold;
    white-space: pre;
    overflow: hidden;
    max-width: 6rem;
}

.bgMessage .message-actions {
    display: none;
    background-color: transparent;
    padding: .25rem;
}

.bgMessage:hover .message-actions {
    display: inline-block;
}

.bgMessage .done-all-icon {
    font-size: 1rem;
    margin-left: .25rem;
    margin-bottom: -.1rem;
    color: white;
}

.bgMessage .done-all-icon.message-read {
    color: #21446c;
}
