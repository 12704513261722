@media (max-width: 960px) {
    .form-login {
        margin: 0;
        width: 100% !important;
        padding: 0 50px;
    }
    .title {
    	font-size: 1.75rem;
    }
    .kwik-logo-container {
        text-align: center;
        margin-top: -15vh;
        margin-bottom: 2.5vh;
    }
    img.kwik-logo {
        width: 50vw !important;
    }
}

.kwik-logo-container {
    text-align: center;
    margin-top: -20vh;
}
img.kwik-logo {
    width: 20vw;
}

.form-login {
	display: flex; 
	height: 99vh; 
	justify-content: center; 
	align-content: center;
	align-items: center;
    width: 40vw;
}
.reset-password {
	margin-top: 15px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}
.title {
	color: #21446C;
    font-weight: 500;
    padding-left: 10px;
}
.login-validation {
	align-items: center;
}