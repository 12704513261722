.background{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fafafa;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainframe{
    display: flex;
    min-height: 30%;
    max-height: 50%;
    width: 50%;
    padding: 1rem;
    border: 2px solid #d2d2d2;
    border-radius: .25rem;
    background-color: white;
}
.mainframe > div{
    overflow:auto
}

.buttons{
    display: flex;
    width: 65%;
    align-items: baseline;
    justify-content: flex-end;
    gap: .5rem;
    padding: 0.5rem 0rem;
}

.fulltext{
    white-space: normal;
}

.fulltext > ul > li > div {
    padding: 0 1rem
}

.fulltext > ul > li{
    white-space: break-spaces
}

.chatswindow{
    border: 1px solid #c3c3c3;
    border-radius: 0.25rem;
    margin: 1rem;
    position:relative
}

.chatinput{
    position: absolute;
    top: -21px;
    left: 15px;
    width: fit-content;
    padding: 0.5rem;
    background: white;
}

.chatitem:hover {
    cursor: pointer;
    background-color: #e2e2e2;
}
