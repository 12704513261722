#tryit-jssip-container {
	display: none;
	border: 3px solid #21446c;
}

#tryit-jssip-container [data-component='Dialpad'] {
    height: 420px !important;
}

.dlpd-root {
	background-color: #5399db !important;
}

.dlpd-root > .initial-view > .display,
.dlpd-root .buttons .button,
.controls > .control {
	background-color: #1b426c !important;
}

.dlpd-root .buttons .button.call,
.dlpd-root .buttons .button.call-reply {
	background-color: #53db70 !important;
	border: 1px solid white;
}

.dlpd-root .buttons .button.back,
.dlpd-root .buttons .button.call-end,
.dlpd-root .buttons .button.call-reject {
	background-color: #db5353 !important;
	border: 1px solid white;
}
