#historyIcon {
	width: 20px;
    color: #212529;
    margin-left: 5px;
}
.chatWindow-box-1 {
    position:relative;
    display: flex; 
    flex-direction: column; 
    height: 100%;
}
.chatWindow-box-2 {
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 11px;
    padding-left: 2%;
    align-items: center;
    width: 100%;
    height: 55px;
    background-color: rgba(33, 68, 108, 0.06);
}
.chatWindow-box-3 {
    cursor: pointer;
    display: flex; 
    align-items: center;
    overflow:auto
}
.chatWindow-box-4 {
    display: flex; 
    padding-left: 1vw; 
    align-items: center;
}
.chatWindow-box-4 p {
    max-width: 25rem;
}
.chatWindow-box-5 {
    display: flex; 
    align-items: center;
}
.headerIcon {
    margin-right: 5px;
    font-size: 1.2rem;
}
.headerIcon.small {
    font-size: 1.2rem;
}
.headerIcon.big {
    font-size: 1.7rem;
}

.footer-msg,
.email-footer-msg {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
    justify-content: center;
    align-items:center;
}

.footer-msg > .MuiAlert-message,
.email-footer-msg > .MuiAlert-message {
    align-self: center;
}

.email-footer-msg .MuiAlert-icon {
    display: none;
}

.alert-btn {
    margin-left: 1vw;
}

.copy-user-icon {
    margin-left: 5px;
    font-size: 17px;
    color: #212529;
}

.push-left {
    margin-left: 15px;
}

.push-right {
    margin-right: 15px;
}

.agent-icon {
    margin-right: 5px;
    font-size: 25px;
    color: #212529;
}

.agent-name {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    cursor: default;
    margin-left: 20px;
}
.label-button{
    margin-left: 20px;
    color: #242424;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 4px 5px;
    border-radius: 4px;
    z-index: 1;
}

.date-centralizer-box{
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem
}

.date-centralizer-line{
    flex: 0.5;
    border: 1px solid rgba(192, 192, 192, 0.452)
}

.date-displayer{
    padding: .5rem;
    background: #acacac;
    color: white;
    border-radius: .25rem;
}