.chat-right-sidebar {
	height: 100%;
	overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.chat-right-sidebar .header {
	display: flex;
    height: 55px;
    min-height: 55px;
    background-color: rgba(33, 68, 108, 0.06);
    align-items: center;
}
.chat-right-sidebar .accordion{
	margin:0;
}

.chat-right-sidebar .accordionHeader{
	background-color: rgba(33, 68, 108, 0.06);
	height: 55px;
    max-height: 55px;
	min-height:55px
}

.chat-right-sidebar .accordion::before{
	margin:0;
	background-color: transparent
}

.chat-right-sidebar .MuiAccordionDetails-root{
	padding:0
}

.chat-right-sidebar .MuiAccordion-root{
	box-shadow: unset;
}


.contactHistory-iconButton {
    margin-left: 10px;
}
.chatSideBar-dropDown-header {
	height: 50px;
	width: 100%;
}
.contactHistory-box-2 {
	display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    flex-direction: column;
    overflow-y: auto;
}
.contactHistory-avatar {
	height: 75px;
    width: 75px;
}
.contactHistory-box-3, .contactHistory-box-4 {
	cursor: pointer;
}
.contactHistory-box-3 {
	margin-top: 10px; 
	margin-bottom: 10px;
}
.contactHistory-box-4 {
	margin-bottom: 10px; 
	font-size: 90%;
}
.contactHistory-box-8 {
	margin-bottom: 20px; 
	font-size: 90%;
	color: #68686B;
}
.contactHistory-box-5 {
	padding: .5rem;
    text-align: -webkit-center;
}
.contactHistory-divider {
	margin-top: 5px;
    margin-bottom: 10px;
}
.contactHistory-box-6 {
	font-size: 11px;
}
.contactHistory-box-7 {
	overflow: auto;
}
.noHistory {
	font-size: 80%;
	margin-top: 2vh;
}

.header-profile {
	display: flex;
	background-color: rgba(33, 68, 108, 0.06);
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.title-profile {
	height: 55px;
    width: 60%;
    text-align: center;
    align-self: center;
    display: flex;
    align-items: center;
    color: #68686B;
}

.avatar-profile {
	margin-top: 20px;
}

.label-item {
	margin-left: 7px;
	background-color: rgb(37, 150, 190);
	color: white;
	padding: 0px;
	border-radius: 4px;
	float: left;
	width: fit-content;
	max-height:2rem
}


.label-list {
	display: flex;
    overflow: auto;
    flex-wrap: wrap;
    row-gap: .25rem;
    max-width: 100%;
    max-height: 13rem;
    padding: .5rem;
}

.history-cursor-badge{
	width: 0.5rem;
    height: .5rem;
    background: green;
    border-radius: 50%;
}
.history-row-cursor{
	padding:0.25rem
}

.history-row-cursor > div {
	display:flex
}

.history-row-cursor.selected{
	background: rgba(128, 128, 128, 0.226);
	border-radius:.25rem
}

table.contact-custom-field-table {
	border-collapse: collapse;
	margin: .5rem 0;
}

table.contact-custom-field-table tbody tr td {
	border: none;
	padding: .25rem 1rem;
}

table.contact-custom-field-table tbody tr td:first-child {
	max-width: 12rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
}
