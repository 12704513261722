.restricted-words-body {
	overflow: auto;
    max-height: 75vh;
    /*padding-left: 40px;*/
}

.input-restricted-words {
    padding: 5px;
}

.title-restricted-words {
	font-size: 15px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
    margin-top: 10px;
}

.buttons-restricted-words {
    padding-top: 15px;
    float: right;
}

.details-restricted-words {
	font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    background-color: #21446C;
    color: white;
    padding: 6px 6px 6px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.details-restricted-words:hover {
	background-color: #21446cb3;
}

.collapse-icon {
	color: white;
	margin-right: 5px;
	font-size: 15px;
}

.restricted-words-company {
	font-size: 22px;
    font-weight: bold;
    color: #21446c;
    margin-left: 55px;
    margin-bottom: 20px;
}

.category-title {
	font-size: 19px;
    font-weight: bold;
    color: #21446c;
    margin-bottom: 10px;
}
.restricted-words-tb {
    margin: 15px 15px;
}

.action-button {
    min-width: 0;
    padding: 0 2.5px;
}