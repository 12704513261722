.chart-container-grid{
    display:grid;
    height: 100%;
    grid-template-columns:  repeat(4, minmax(0, 1fr));
    gap: .5rem;
    padding: .5rem;
    background: #f6f6f6
}

.historical-records-mainframe,
.dashboard-mainframe{
    height:100%;
    overflow:auto
}

.dashboard-mainframe.historic{
    
}

.chart-container.queue{
    position: relative;
    background:white;
    border-radius: .25rem;
}

.chart-fixed-text{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    font-weight: bold;
}


.chart-container-grid .recharts-responsive-container {
    width: 100%;
    height: 100%;
    border: 3px solid #e2e2e2;
    border-radius:.25rem;
    min-height: 10rem
}