#chatIntro-box-1 {
	display: flex; 
	flex-direction: column; 
	justify-content: center; 
	align-items: center;
	height: 80vh;
}
#chatImage {
	width: 30%;
}
#chatIntro-box-2 {
	padding-top: 1vw; 
	width: 50%;
}