.transfer-item:not(:last-child) {
    margin-bottom: 5px;
}

.transfer-item:hover {
    cursor:  pointer;
    background-color: #ddd;
}

.selected {
    cursor:  pointer;
    background-color: #ddd;
}

#gridItem {
    text-align: center;
    align-self: center;
}

#currentQueue {
    font-size: 75%;
    text-align: right;
    font-style: italic;
}
