#messageArea-history {
  padding-top: 3vh;
  height: 80vh;
  padding-left: 3%;
  padding-right: 2vh;
  margin-bottom: 0;
  overflow: auto;
}

.actions {
	position: absolute;
    right: 0;
}

.history-header, .history-footer {
	text-align: center;
  justify-content: center;
  background-color: rgba(33, 68, 108, 0.06);
  width: 100%;
}

.history-header {
  border-bottom: 1px solid #e7e7e7;
}

.history-footer {
  border-top: 1px solid #e7e7e7;
  align-self: center;
}

svg.whatsapp {
  fill: #34af23;
}

svg.telegram {
  fill: #3390ec;
}

svg.instagram {
  fill: #fd6b10;
}

svg.messenger {
  fill: #0866ff;
  display:flex
}

svg.msteams {
  fill: #6264A7;
}

svg.email {
  fill: #757575;
}

svg.instantchat {
  fill: #5399DB;
}

svg.integration {
  fill: #5c736d;
}

.dateTime {
  fill: #af2323;
}

.key {
  fill:  #af9423;
}

.history-header .MuiGrid-container {
  display: flex;
  justify-content: space-evenly;
}

.history-telephone, 
.history-date {
  display: flex;
  align-items: center;
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-footer h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-telephone svg, 
.history-date svg,
.history-footer h2 svg {
  margin-right: 0.5rem;
}
