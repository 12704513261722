.outer-Screen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.screen-wrapper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.nomargin {
    margin-left: 0 !important
}

.toolbarWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.table-screen {
    flex:1
}

.btnBlue {
    background-color: #21446C
}
.btnGreen {
    background-color:#5F8963
}
.btnBlue:hover {
    background-color:rgb(23, 47, 75)
}
.btnGreen:hover {
    background-color:rgb(66, 95, 69);
}

.inner-Screen {
    display: flex;
    flex-wrap: wrap;
}

.containedImportButton:hover,
.containedImportButton {
    all: unset;
    padding: .5rem;
    width: 100%;
    padding-right: 1.5rem
}

@media screen and (max-width:610px) {
    .MuiDataGrid-filterForm{
        gap: .5rem;
        flex-direction: column;
    }
    .MuiDataGrid-filterForm div{
        width:100%;
        align-items: flex-end
    }
}

.spinner {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.screen-wrapper .outer-Screen .button-table {
    display: flex;
    align-items: center;
}

.campaign-decide-platform{
    display:flex;
    width:100%;
    margin:0rem 1rem 1.5rem 0rem;
    border:1px solid #dadada;
    border-radius:0.25rem
}

.campaign-decide-platform button{
    flex:1
}