.settings-body {
	overflow: auto;
    padding: 1rem;
    padding-top: 0.5rem;
}

.flexbox{
    display:flex;
    flex-direction: column;
}

.title-settings {
	font-size: 15px;
	display: flex;
	align-items: center;
}

.buttons-settings {
    float: right;
    margin-top: 20px;
}

.buttons-settings-2 {
    float: left;
    margin-top: 20px;
}

.details-settings {
	font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    background-color: #21446C;
    color: white;
    padding: 6px 6px 6px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.details-settings:hover {
	background-color: #21446cb3;
}

.collapse-icon {
	color: white;
	margin-right: 5px;
	font-size: 15px;
}

.settings-company {
	font-size: 22px;
    font-weight: bold;
    color: #21446c;
    margin-left: 55px;
    margin-bottom: 20px;
}

.category-title {
	font-size: 19px;
    font-weight: bold;
    color: #21446c;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.restricted-words-tb {
    margin: 20px 15px;
}

.action-button {
    min-width: 0;
    padding: 0 2.5px;
}

.active-send {
    color: #5F8963;
}

.inactive-send {
    color: #C95F50;
}

.action-button {
    min-width: 0;
    padding: 0 2.5px;
}

.push-bottom {
    margin-bottom: 20px !important;
}

.settings-audio-player {
    width: 210px;
    height: 40px;
    margin-right: 25px;
}

.settings-audio-notification {
    display: flex;
    align-items: center;
}