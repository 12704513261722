.logo {
	height: 2.5em;
	vertical-align: middle;
}

.logoFavIcon {
	width: 3em;
	display: flex;
	position: absolute;
	align-self: center;
	margin-top: 0.5em;
}
.toolbar-body {
	display: flex;
	z-index: 100;
}

.body-kwik {
	display: flex;
	height: 100%;
	overflow-y: auto;
}

.mobile-navigation {
	display: none;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #F4F4F4 #dad7d7;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}

*::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}

*::-webkit-scrollbar-thumb {
    background: #dad7d7;
}

@media (max-width: 60rem) {
	.app-bar {
		display: none;
	}
	.left-drawer {
		display: none;
	}

	.mobile-navigation {
		display: block;
	}
}

.trial-info-alert {
	margin-left: 1rem;
	padding: 0 1rem;
    max-height: 2.25rem;
    margin-top: 0.25rem;
}
