.graphics-actions {
    display: flex;
}

.icon-export, .icon-visibility {
    color: white;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 125px;
    z-index: 1300;
}

.custom-row{
    background-color:gainsboro
}

.icon-export {
    top: 13px;
    right: 170px;
}

.report-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.report-container .report-header {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
}

.report-container .report-header .report-header-left,
.report-container .report-header .report-header-right {
    display: flex;
    flex: 1;
}

.report-container .report-header .report-header-left.hidden {
    display: none;
}

.report-container .report-header .report-header-right {
    justify-content: flex-end;
}

.report-container .report-header .report-header-left .MuiBox-root:first-child {
    margin-left: 0;
}

.report-container .report-header .report-header-left .MuiBox-root {
    margin-left: 0.25rem;
}

.report-container .report-header button {
    height: 100%;
}

.report-container .report-header .report-header-left button,
.report-container .report-header .report-header-right div:not(:first-child) {
    margin-left: 0.5rem;
}

.report-container .report-header .report-header-right .btn-switch {
    align-items: center;
}

.report-container .report-header .report-header-right .btn-switch.inactive.Mui-disabled {
    background-color: #e0e0e0;
}

.report-container .report-header .report-header-right .btn-switch.inactive:hover {
    text-decoration: none;
    background-color: rgba(33, 68, 108, 0.04);
    color: #21446C;
}

.report-container .report-header .report-header-right .btn-switch.inactive svg,
.report-container .report-header .report-header-right .btn-switch.inactive {
    background-color: transparent;
    color: #21446C;
    fill: #21446C;
}

.report-container .report-content {
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
}

.report-graphs {
    display: flex;
    overflow: hidden;
}

.report-graphs .MuiGrid-container .MuiGrid-item {
    height: 50%;
}

.report-graphs .MuiGrid-container .MuiGrid-item .chart-box {
    height: 100%;
}

.report-container .report-content .report-graphs div.MuiGrid-root.MuiGrid-container {
    padding-left: 0;
}

.report-container .report-content .whatsapp {
    fill: #34af23;
    vertical-align: middle;
}

.report-container .report-content .telegram {
    fill: #3390ec;
    vertical-align: middle;
}

.report-container .report-content .instagram {
    fill: #fd6b10;
    vertical-align: middle;
}

.report-container .report-content .msteams {
    fill: #6264A7;
    vertical-align: middle;
}

.report-container .report-content .email {
    fill: #757575;
    vertical-align: middle;
}

.report-container .report-content .instantchat {
    fill: #5399db;
    vertical-align: middle;
}

.report-container .report-content .survey-cell {
    display:  flex;
    align-items: center;
}

.report-container .report-content .answers-survey {
    display: flex;
    place-self: center;
    margin-left: 10px;
    font-weight: bold;
    width: 100%;
    justify-content: center;
}

.report-container .report-content .answer {
    margin-left: 5px;
    margin-right: 5px;
}

.report-container .report-content .answer-red {
    color: #C95F50;
    font-size: 15pt;
}

.report-container .report-content .answer-yellow {
    color: #ed6c02;
    font-size: 15pt;
}

.report-container .report-content .answer-green {
    color: #5F8963;
    font-size: 15pt;
}

.report-container .report-content .graph-overlay svg{
    fill:inherit
}

.menuItem:hover {
	text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
	cursor:pointer
}
.menuItem {
	min-height: auto;
	font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.report-filters > div{
    margin-bottom: 1rem
}

.filedrop-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    line-height: 1.5;
}

.filedrop-container .file-drop {
    position: relative;
    width: 100%;
    height: 100%;
}

.filedrop-container .file-drop .file-drop-target {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.filedrop-filename {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.filedrop-filename .greenshadow {
    box-shadow: 1px 1px 5px 0px green;
}

.filedrop-filename .redshadow {
    box-shadow: 1px 1px 5px 0px red;
}

.report-authenticate-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #21446c;
    padding: 0.25rem;
}

.report-authenticate-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.report-authenticate-container .report-authenticity-seal {
    border: 2px solid #1b426c;
    padding: 2rem;
    margin: 0 auto;
    border-radius: 0.75rem;
}

.report-authenticate-container .report-authenticity-seal .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report-authenticate-container .report-authenticity-seal .logo {
    height: 5rem;
    margin-top: -1.5rem;
    margin-bottom: -2.5rem;
}

.report-authenticate-container .report-authenticity-seal table {
    width: 100%;
    margin-top: 3rem;
}

.report-authenticate-container .report-authenticity-seal table td {
    padding: 0.5rem;
}

.report-authenticate-container .report-authenticity-seal table td:first-child {
    font-weight: bold;
    text-align: right;
}

.report-action-buttons button {
    padding: 0;
    min-width: unset;
}


.builder-tag-list,
.builder-tag-list li{
    padding: 0
}