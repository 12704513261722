.graphics-actions-exit {
	position: absolute;
    right: 0;
}
.active-graphics {
	padding: 30px;
	align-self: center;
}
.settings-title {
	align-self: center;
    font-size: 20px;
    padding-top: 15px;
}