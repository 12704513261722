.category-title {
	margin: 0
}

.category-title.checkbox {
	font-size: 17px;
    margin-bottom: 1px;
}

.category-title.checkbox.unchecked {
	color: grey;
}

.escalation-time-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}

.escalation-time-row .remove-add-icon {
	border-radius: 50%;
    min-width: 0;
    width: 2rem;
    height: 2rem;
}

.piloto-modal-flex .multiple-selections {
	padding-top: 0;
	padding-bottom: 0;
}

.escalation-container {
	height: 100%;
}
