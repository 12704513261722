.emoticon-icon {
    color: #21446C;
    font-size: 30px;
    cursor: pointer;
}
.textarea-autosize {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-left: 5px;
    margin-right: 5px;
    outline: 0;
    width: 100%;
    overflow:auto;
    max-height: 5rem;
    overflow-wrap: anywhere;
}
.textarea-chat {
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 15px;
    background-color:white
}

.audio-baloon-message{
    position: absolute;
    left: -2rem;
    border-radius: .25rem;
    padding: 0.5rem;
    font-size: 0.7em;
    background-color: white;
    color: red;
    top: -2rem;
    border: 1px solid #d1d1d1;
}

.textarea-pm {
    padding: 5px;
}
.wpp-emoji{
    width:18px;
    vertical-align: text-bottom;
    cursor: text;
}

.emojitextarea-wrapper-box{
    position:relative;
    display: inline-grid;
    align-self: self-end;
    width: 100%;
    margin-right: 1rem;
}

.emojitextarea-placeholder{
    position: absolute;
    top: .6rem;
    left: 1rem;
    opacity:.5;
    pointer-events:none
}

.textarea-messagebox {
    overflow:auto;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 15px;
    background-color:white;
    margin: 0;
    min-height: 2.5rem;
    padding-left: 2.5rem;
    max-height: 5rem;
    
}

.textarea-messagebox.padding-for-dropdown{
    padding-right:2.5rem
}

.emojitextarea-placeholder.offset{
    left:2.5rem;
    opacity: unset;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
}

.emojitextarea-placeholder.offset.shrink{
    left: 0.75rem;
    top: -0.4rem;
    padding: 0rem 0.25rem 0rem 0.25rem;
    background-color: white;
    line-height: unset;
}

.textarea-messagebox-button{
    position: absolute;
    top: 0.35rem;
    left: 0.3rem;
    z-index:1
}


.emojitextarea-box-limit {
    position: absolute;
    right: 12px;
    bottom: 0;
    color: #c4c4c4;
    user-select: none;
    font-size: 0.7em;
}

.emojitextarea-box-limit.overflow{
    color: red
}
