.email-add-button {
	display: flex;
	margin-top: 0.5rem;
}

.email-add-button .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
	align-items: center;
}

.email-add-button button {
	margin-right: 1rem;
}

.email-add-button .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
	padding: 0 10px;
}

.add-template-dialog-content {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.add-template-dialog-content .add-template-viewer {
	width: 100%;
	border: 1px solid lightgrey;
	border-radius: 5px;
	padding: 0.5rem;
	overflow: auto;
}

.add-template-dialog-content {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.add-template-dialog-content .add-template-viewer {
	width: 100%;
	border: 1px solid lightgrey;
	border-radius: 5px;
	padding: 0.5rem;
	overflow: auto;
}

.PhoneInput input{
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
}