.manage-calls-container {
	padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
	overflow: auto
}

.manage-calls-container .manage-calls-header {
	padding-bottom: 1rem;
}

.manage-calls-container .manage-calls-content {
	display: flex;
	flex: 1;
	gap:1rem;
	overflow-y: hidden;
	padding: 0.5rem 0rem;
}
