.custom-field-row {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	margin-bottom: 1rem;
}

.custom-field-row .remove-row-icon {
	border-radius: 50%;
    min-width: 0;
    width: 2rem;
    height: 2rem;
    margin-top: 3px;
}
