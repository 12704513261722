.tooltip-icon {
    color: #21446C;
    position: relative;
    margin-bottom: auto;
    z-index: 1000;
    font-size: 1.25rem;
    margin-left: 5px;
}

.tooltip-icon:hover {
    cursor: pointer;
}
