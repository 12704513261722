.itemHistory {
	font-size: 90%;
	cursor: pointer;
}
.itemHistory:hover {
	text-decoration: underline;
}
.fileIcon {
	width: 15px;
	margin-right: 5px;
}

.conversationList-box {
	height: 100%;
	width: 100%;
}
.kwik-main-chat {
	width: 100%;
	height: 100%; 
	display: flex;
}

.message-box {
	height: 100%;
	border-left: 1px solid rgba(0, 0, 0, 0.12);
	background-color: white;
}

.column-grid {
	height: 100%;
}

.tabs-list-blocked{
	opacity:0.5;
	cursor:no-drop
}
.tabs-list-blocked button{
	cursor:inherit
}
.tabs-list-blocked span{
	cursor:inherit
}

.loader-skeleton{
	display:flex;
	align-items: center;
	justify-content: center;
}