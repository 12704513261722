@media (max-width: 760px) {
	.webphone-menu-container {
		display: none;
	}	
}

.webphone-menu-container {
	background-color: #5166a6;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
    gap: .15rem;
    color: white;
}

.webphone-menu-container span {
	margin-top: .55rem;
	font-size: 90%;
}

.webphone-menu-container .toolbar-icon {
	color: white;
	font-size: 1.25rem;
}

.webphone-menu-container .toolbar-icon.green {
	color: #17e917;
}

.webphone-menu-container .toolbar-icon.yellow {
	color: yellow;
}

.webphone-menu-container .toolbar-icon.red {
	color: red;
}

.webphone-container > div.MuiPaper-root {
	margin-top: 2rem;
	background: transparent;
}

.webphone-container > div.MuiPaper-root > ul {
	padding: 0 !important;
}

.webphone-container > div.MuiPaper-root > ul #tryit-jssip-container {
	border-radius: .25rem;
	box-shadow: 5px 5px 5px grey !important
}

.webphone-container .show-cti-button {
	position: relative;
}

.webphone-container .show-cti-button button {
	writing-mode: vertical-rl; 
    transform: rotate(180deg); 
    background-color: #1b426c;
    color: white;
    padding: 0;
    border-radius: .25rem;
    height: 4.5rem;
    margin-right: 0.5rem;
    margin-top: 3.75rem;
}

.webphone-container .show-cti-button .arrow-right,
.webphone-container .show-cti-container .arrow-right {
	position: absolute;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #1b426c;
}

.webphone-container .show-cti-button .arrow-right {
    top: 23%;
    right: 0;
}

.webphone-container .show-cti-container .arrow-right {
	top: 50%;
    right: -8px;
}

.webphone-container .show-cti-container {
    position: relative;
    padding: 8px 16px;
    background-color: #1b426c;
    border-radius: 4px;
    margin-right: 0.75rem;
    margin-top: 3.75rem;
    max-height: fit-content;
    color: white;
    min-width: 10rem;
    width: -webkit-fill-available;
}

.webphone-container .show-cti-container .cti-data {
	display: flex;
	flex-direction: column; 
	gap: 1rem;
	padding: .25rem 0 1rem 0;
}

.webphone-container .show-cti-container .cti-data .header {
	font-weight: bold;
	text-align: center;
}

.webphone-container .show-cti-container .cti-data .header .copy-btn {
	color: white;
	margin-left: .25rem;
}

.webphone-container .show-cti-container .cti-data .header .close-btn {
	color: white;
	position: absolute;
    right: 0.25rem;
    top: .25rem;
    padding: .25rem;
}

.webphone-container .show-cti-container .cti-data .header .copy-btn svg,
.webphone-container .show-cti-container .cti-data .header .close-btn svg {
	font-size: 75%
}
