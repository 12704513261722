.clients-secondary-list-items {
	display: flex;
	flex-direction: row;
	gap: .25rem;
	margin-top: .5rem;
	font-size: 90%;
}

.clients-secondary-list-items svg {
	height: 1rem;
    margin-bottom: -3px;
}
