
.monthPicker div#simple-select-weekdays,
.monthPicker div#simple-select-months {
    overflow: auto;
}
.monthPicker .MuiBox-root.css-yi3mkw{
    max-height: 1rem;
}

.dateSelectorDiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.maxWidth .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width:100%
}