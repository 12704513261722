.messageArea-history {
  padding-top: 3vh;
  height: 80vh;
  padding-left: 3%;
  padding-right: 2vh;
  margin-bottom: 0;
  overflow: auto;
}
.contact-history-grid {
  max-width: 300px;
  min-width: 250px;
  overflow: hidden;
}
.contact-history-box-1 {
  height: 100%;
  width: 100%;
  background-color: rgba(33, 68, 108, 0.06);
  border-left: 1px solid #e0e0e0;
}
.contact-history-iconButton {
  margin-top: 10px;
    margin-left: 10px;
}
.contact-history-box-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: -5vh;
}
.contact-history-avatar {
  height: 75px;
    width: 75px;
}
.contact-history-box-3, #contact-history-box-4 {
  cursor: pointer;
}
.contact-history-box-3 {
  margin-top: 10px; 
  margin-bottom: 5px;
}
.contact-history-box-4 {
  margin-bottom: 5px; 
  font-size: 90%;
}
.contact-history-box-8 {
  margin-bottom: 20px; 
  font-size: 90%;
  color: #68686B;
}
.contact-history-box-5 {
  min-width: 300px;
    text-align: center;
}
.contact-history-divider {
  margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 18px;
    width: 88%;
}
.contact-history-box-6 {
  font-size: 11px;
}
.contact-history-box-7 {
  overflow: auto;
  max-height: 30vh;
}
.noHistory {
  font-size: 80%;
  margin-top: 2vh;
}
.itemHistory {
    font-size: 90%;
    cursor: pointer;
}
.fileIcon {
    width: 15px;
    margin-right: 5px;
}

.grid-scroller{
  overflow: hidden;
  display: flex;
}

.grid-insider{
  display:flex;
  flex:1
}