#conversationItem-box-1 {
	height: 100px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: white;
	justify-content: center;
	position: relative;
}

#conversationItem-box-1 svg {
	font-size: 1.25rem !important;
}

#conversationItem-box-1.active {
	background-color: #21446c;
	border-left: 6px solid #7fc1ff;
}

#conversationItem-box-1 #conversationItem-box-3 svg {
	margin-left: 0.1rem;
}

#conversationItem-listItem {
	align-items: flex-start;
	background-color:inherit
}
#conversationItem-listItemText-1 {
	width: 65%;
    color: white;
	background-color:inherit
}
#conversationItem-listItemText-1 span {
	background-color:inherit
}

.conversationItem-box-2.active {
	color: white;
}

.conversationItem-box-2.active .direction-icon {
	color: white;
}

.conversationItem-box-2 {
	margin-bottom: 3px; 
	margin-top: 2px;
	display: flex;
	color: black;
	align-items: center;
}

#conversationItem-box-3 {
	display: flex; 
	flex-direction: column; 
	margin-left: 25px;
	margin-top: 5px;
}
#conversationItem-box-4 {
	display: flex; 
	align-items: center; 
	flex-direction: row-reverse;
}
#conversationItem-box-5 {
	display: flex; 
	align-items: center; 
	flex-direction: row-reverse; 
	margin-top: 3%;
	gap: .25rem;
}

.queueIcon, .calendarIcon, .infoIcon, .twenty-for-hour-window-icon {
	width: 20px;
}
.queueIcon, .calendarIcon {
	margin-top: -0.5vh;
}
.conversationItemIcon {
	cursor: pointer;
	margin-left: 0.2rem;
    margin-top: -0.1rem;
    width: 1.1rem;
}
.whatsAppIcon {
	color: #38a951;
}
.telegramIcon {
	color: #3390ec;
}
.instagramIcon {
	color: #fd6b10;
}
.messengerIcon {
	color: #0866ff;
}
.msteamsIcon {
	color: #6264A7;
}
.emailIcon {
	color: #757575;
}
.instantChatIcon {
	color: #5399DB;
}
.twenty-for-hour-window-icon {
	margin-right: 3px;
}
#conversationItem-box-6 {
	margin-left: 10px; 
	margin-right: 5px;
}
#conversationItem-box-7 {
	padding-left: 3%;
	padding-right: 3%; 
	position: relative;
	width: 100%;
	top: -5px;
	left: 0;
}

.direction-icon {
	margin-right: 0.25rem;
	color: #21446c;
}

.badgeItem-unreadMessages > span > span {
	background-color: #38a951;
	color: white;
	position: relative;
	height: 17px;
	min-width: 17px;
	font-size: 7pt;
	top: 2rem;
	right: -.5rem;
}

.badgeItem-zero > span > span {
	margin-left: -1.85rem;
}

.badgeItem-ten > span > span {
	margin-left: -2.4rem;
}

.badgeItem-hundred > span > span {
	margin-left: -2.9rem;
}

#arrowDropDownIconActive {
	fill: white;
}
#arrowDropDownIconInactive {
	fill: #21446c;
}
.infoConversation {
	display: none;
}
.infoConversation {
	position: absolute;
	z-index: 1000;
	background-color: white;
	border: 1px solid #e0e0e0;
    border-radius: 10px;
    color: #21446c;
    width: 150px;
    padding: 10px;
    margin-top: 5px;
    margin-left: -15px;
    cursor: alias;
    display: block;

}
#amountMessages {
	text-align: -webkit-center;
}
#buttonInfoConversation {
	text-align: end;
}

.queue-name > p {
	margin-left: 10px; 
	margin-right: 5px;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
    margin-bottom: 5px;
}

.supervised-msg {
	background-color: #ffd699;
}
.special-inline-flex{
	overflow: hidden;
    display: flex;
    padding: 0;
	flex-wrap:wrap;
	max-height: 1.5rem;
	row-gap:.25rem;
	background-color:inherit
}

.wrapped-parent .special-inline-flex {
    padding: 0;
	white-space: pre;
}

.wrapped-parent{
	flex: 1;
	display:flex;
	align-items: center;
	position:relative;
	min-height: 2rem;
	background-color:inherit
}

.wrapped-relevant {
	position: relative;
	background-color: inherit;
	width: 100%;
	min-height: 1rem;
	max-width: 5rem;
	transition: max-width 0.5s ease;
}

.wrapped-relevant:hover {
	position: relative;
	max-width: none;
}

.wrapped-relevant:hover ul:hover {
	overflow-y: auto;
    max-height: 2rem;
    min-height: 100%;
    position: relative;
    z-index: 1;
    background-color: inherit;
	align-items: center;
}

.connect-email-icon {
	color: white;
	padding: 0.25rem;
}

.connect-email-icon:hover {
	background-color: #5399db;
}
