.managers-container {
	width: 100%;
	height: 100%;
	padding: 1rem;
}

.action-button {
	min-width: 0;
	padding: 0 2.5px;
}

.action-button.delete {
	color: #C95F50;
}
