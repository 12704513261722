.settings-title-v2{
    font-size: 1.5em;
    font-weight: bold;
    color: #21446c;
}

.sublabel{
    font-size:.8em;
    font-weight: lighter;
    color:rgb(133, 133, 133)
}

.settings-flexcolumn{
    display:flex;
    flex-direction: column;
    gap:1rem;
    height:100%
}

div .clear-padding{
    padding:0
}

.settings-button-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: .25rem;
    border: 1px solid #d5d5d5;
}
.settings-button-item > div{
    text-transform:none
}

.settings-content-v2{
    display:flex;
    flex-direction: column;
    height:100%;
    gap:1rem
}

.settings-content-v2 .BuilderContainer{
    padding:0
}

.settings-free{
    width: 100%;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-radius: .25rem;
}

.settings-list-v2{
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow:auto
}

.settings-free-v2{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
}

.settings-free-v2 .settings-body{
    width:100%
}

.settings-content-v2 .options-menu-title{
    display:flex;
    gap:1rem
}

.settings-display-v2{
    width: 100%;
    height: 100%;
    overflow:auto;
}

.settings-overflow{
    overflow:auto;
}