.onboarding-content-container {
	background-color: #5399DB;
    min-height: 100vh;
    color: white;
    padding: 10vh 5vw;
}
.onboarding-kwik-logo {
	width: 15vw;
}
.onboarding-greetings {
	font-size: 120%;
}
.onboarding-info,
.ongoarding-footer {
    line-height: 4vh;
}
.onboarding-connect-button {
    border: 0;
    cursor: pointer;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    padding: 6px 16px;
    border-radius: 4px;
    color: #fff;
    background-color: #21446C;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.onboarding-connect-button:hover {
    background-color: #1b3450;
}
