.template-item span {
    margin-bottom: 1vh;
    font-weight: 500;
}

.template-item:not(:last-child) {
    margin-bottom: 0.5vh;
}

.template-item:hover {
    cursor: pointer;
    background-color: #ddd;
}

.selected {
    cursor: pointer;
    background-color: #ddd;
}

#gridItem {
    text-align: center;
    align-self: center;
}

.push-top {
    margin-top: 3vh;
}

.input-parameter {
    margin-top: 2vh;
}
