.tabActive{
	color:#5399DB;
	border-bottom:2px solid #5399DB;
	opacity: 1;
}

.tabInactive{
	color:#000000;
	opacity: 1;
}

.settings {
	display: flex;
    flex-direction: column;
}

.menu-settings {
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
    justify-content: center;
}

.settings-body {
	overflow: auto;
	height: 100%;
}

.color-picker {
	border: 1px solid #bbbbbbbd;
	margin-top: 1.5rem;
	padding: 1rem;
	position: relative;
	border-radius: 0.25rem;
}

.color-picker-label {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: 10px;
    font-size: .9rem;
    padding: 0rem .2rem;
    background-color: white;
}



.color-picker-flex {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.color-picker-input {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
	cursor:pointer;
	animation: ease-in-out;
	animation-duration: 1s;
}

.color-picker-input:hover{
	filter:brightness(0.9)
}

.color-picker-input.color-selected {
    scale: 1.25;
    box-shadow: 0px 0px 10px 2px #00ebff;
	animation: ease-in-out;
	animation-duration: 1s;
}

.cursor-unset{
	cursor:unset
}

.no-max-width.maximized div .MuiPaper-root{
	width: 100%;
	height: 100%
}

.no-max-width,
.no-max-width div,
.no-max-width div div{
	max-width:unset;
	max-height:unset
}

.no-max-width div div.MuiDialog-paper{
	margin:0;
}

.vivre-ura-dialog{
	width: 80vw;
	height: 75vh;
	overflow: hidden;
	position: relative
}

.vivre-ura-dialog.maximized{
	width:100%;
	height:100%;
	padding: 0.5rem;
}

.MuiTooltip-popper{
	z-index:999999
}

.flex-min-width-wrap{
	display:flex;
	flex-wrap:wrap;
	min-width:50%;
	gap:1rem;
}

.predefined-settings{
	height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}