.telegram-mumbers-info {
	display: flex;
	margin-top: 0.5rem;
}

.telegram-info p {
	font-weight: bold;
}

.push-s-bottom {
	margin-bottom: 0.5rem;
}

.push-s-top {
	margin-top: 0.5rem;
}

.telegram-info p:before {
	content: "\2022";
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.telegram-bots {
	padding-left: 1.5rem;
}

.telegram-bots table tr td:not(:last-child) {
	padding: 0 0.5rem;
}

.telegram-bots table tr td:first-child:before {
	margin-right: 0.5rem;
	content: "\2022";
}

.telegram-bots table tr td svg {
	font-size: 1.25rem;
	color: #C95F50;
}

.telegram-bots table tr td svg.editicon {
	color: #21446C;
};



