#attachmentArea-box-1 {
	height: 100%; 
	width: 100%;
}
#attachmentArea-box-2 {
	height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.document-align-master{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.preview-select-options {
    padding-bottom: 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#insertDriveFileIcon {
	font-size: 30vh;
    color: gray;
}
#attachmentArea-box-3 {
	width: 65%; 
	justify-content: center; 
	display: flex;
}

.send-attachment-btn {
	margin-left: 20px;
}
