#footer {
	height: 60px; 
	display: flex;
    padding: .5rem;
    align-items: center;
    width: 100%;
    background-color: rgba(33, 68, 108, 0.06);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.reply-message-area {
    display: flex;
    align-items: center;
    background-color: #e5f6fd;
    padding: 1rem;
}

.reply-message-area button {
    margin-left: auto;
    padding: .25rem;
}
