.item:hover {
    cursor:  pointer;
    background-color: #ddd;
}
.button-update {
    height: 15px;
    width: 15px;
    margin-right: 5px;
}
.button-delete {
    height: 15px;
    width: 15px;
    margin-right: -10px;
}
.icon-add {
    color: #21446C;
}
.icon-update {
    height: 20px;
    width: 20px;
}
.icon-delete {
    height: 20px;
    width: 20px;
    color: #C95F50;
}
.icon-cancel {
    color: #C95F50;
}
.add-category {
    margin-top: 5px;
}
.add-category:hover {
    background-color: white;
}
.dialog-content {
    border-bottom: 0;
    height: 75px;
    overflow: hidden;
}