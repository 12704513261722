.email-template-padding{
    display: flex;
    padding: 1rem 0rem;
    justify-content: flex-end;
}

.add-template-viewer.dispersed{
    max-height: 60svh;
}

.add-template-dialog-content.dispersed{
    max-height: 60svh;
}

.dispersed  .special-template-textarea div #template-text-field{
height: 100% !important
}

.template-email-wrapper{
    display:flex;
    flex-direction: column;
    height:100%
}

.template-email-wrapper .add-template-dialog-content{
    flex: 1
}